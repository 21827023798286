@import '~@omnichannel/font-arboria';

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 900, bolder;
  src: url('./utils/assets/fonts/Archivo/Archivo-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 900, bolder;
  src: url('./utils/assets/fonts/Archivo/Archivo-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700, bold;
  src: url('./utils/assets/fonts/Archivo/Archivo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 700, bold;
  src: url('./utils/assets/fonts/Archivo/Archivo-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400, normal;
  src: url('./utils/assets/fonts/Archivo/Archivo-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 400, normal;
  src: url('./utils/assets/fonts/Archivo/Archivo-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  src: url('./utils/assets/fonts/Archivo/Archivo-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 300;
  src: url('./utils/assets/fonts/Archivo/Archivo-LightItalic.ttf') format('embedded-opentype');
}

@font-face {
  font-family: 'Archivo-Thin';
  font-style: normal;
  font-weight: 100, lighter;
  src: url('./utils/assets/fonts/Archivo/Archivo-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 100, lighter;
  src: url('./utils/assets/fonts/Archivo/Archivo-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500, normal;
  src: url('./utils/assets/fonts/Archivo/Archivo-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 500, normal;
  src: url('./utils/assets/fonts/Archivo/Archivo-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 350;
  src: url('./utils/assets/fonts/Archivo/Archivo-Regular.ttf');
}

/*
 */

@font-face {
  font-family: 'ProximaNova';
  src: url('./utils/assets/fonts/ProximaNova/proxima_nova_thin-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proxima_nova_thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./utils/assets/fonts/ProximaNova/proximanova-regular-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proximanova-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proximanova-mediuuploadedfile';
  src: url('./utils/assets/fonts/ProximaNova/proximanova_-_medium-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proximanova_-_medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novasemibold';
  src: url('./utils/assets/fonts/ProximaNova/proximanova_-_semibold-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proximanova_-_semibold-webfont.woff') format('woff');
  font-weight: 550;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./utils/assets/fonts/ProximaNova/proxima_nova_bold-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proxima_nova_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./utils/assets/fonts/ProximaNova/proxima_nova_extrabold-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proxima_nova_extrabold-webfont.woff') format('woff');
  font-weight: 650;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novablack';
  src: url('./utils/assets/fonts/ProximaNova/proxima_nova_black-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proxima_nova_black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

/*
 * Próxima Nova Alt
 */

@font-face {
  font-family: 'ProximaNovaAlt';
  src: url('./utils/assets/fonts/ProximaNova/proxima_nova_alt_bold-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proxima_nova_alt_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaAlt';
  src: url('./utils/assets/fonts/ProximaNova/proxima_nova_alt_light-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proxima_nova_alt_light-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaAlt';
  src: url('./utils/assets/fonts/ProximaNova/proxima_nova_alt_thin-webfont.woff2') format('woff2'),
    url('./utils/assets/fonts/ProximaNova/proxima_nova_alt_thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Arboria-Book', 'Arboria', sans-serif;
  font-style: normal;
  font-weight: 400, normal;
  src: url('~@omnichannel/font-arboria/assets/fonts/Arboria/Arboria-Book.eot') format('embedded-opentype'); /* For IE6-8 */
  src: local(arboria), local('arboria'), local('Arboria'), local('Arboria-Book'),
    url('~@omnichannel/font-arboria/assets/fonts/Arboria/Arboria-Book.woff2') format('woff2'),
    url('~@omnichannel/font-arboria/assets/fonts/Arboria/Arboria-Book.woff') format('woff'),
    url('~@omnichannel/font-arboria/assets/fonts/Arboria/Arboria-Book.ttf') format('truetype');
}
